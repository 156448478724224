#choose-bond-view {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;

  .orkan-card .bond-hero h4 {
    font-weight: 600 !important;

    .MuiSkeleton-root {
      margin: auto;
    }
  }

  .claim-bonds-card {
    .global-claim-buttons {
      width: 100%;
      margin: 15px 0px;

      button {
        max-width: 233px;
      }
    }

    .small.global-claim-buttons {
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;

      button {
        max-width: unset;
        width: 90%;
      }
    }
  }

  .bond-data-card {
    margin: auto;

    .orkan-pairs {
      display: flex;
      margin-right: 15px;
    }

    .bond-pair {
      display: flex !important;
      align-items: center;
      justify-content: left;
      margin-bottom: 15px;

      .MuiBox-root {
        margin-right: 10px;
      }

      .bond-name {
        svg {
          margin-left: 5px;
          vertical-align: middle;
          font-size: 15px;
        }
      }
    }

    .data-row {
      margin-bottom: 13px;

      .bond-price {
        display: flex;
        align-items: center;

        svg {
          margin-right: 5px;
        }
      }
    }

    .bond-link {
      margin-top: 10px;
      text-align: center;

      a {
        width: 96%;
      }
    }
  }

  .currency-icon {
    display: inline-block;
    min-width: 15px;
  }

  .claim-bond-card-buttons {
    flex-wrap: wrap;
    width: 100%;

    button {
      min-width: 48%;
      margin: 5px 0px;
    }
  }

  .MuiTableCell-root {
    p {
      margin: 0 !important;
      display: flex !important;
      align-items: center;
      // justify-content: flex-start;
      white-space: nowrap;

      img {
        margin-right: 3px;
      }
    }
  }

  .MuiTableBody-root .MuiTableRow-root .MuiTableCell-alignLeft.bond-name-cell {
    justify-content: space-between;
    display: flex;

    div.bond-name {
      width: 60%;
      margin-left: 5px !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;
      align-items: flex-start;

      p {
        margin-bottom: unset !important;
        line-height: 140% !important;
      }

      svg {
        margin-left: 3px !important;
        font-size: 1.2em !important;
      }
    }
  }
}

#choose-bond-view .MuiPaper-root.orkan-card {
  padding: 24px 0px 0px 0px;
}

#choose-bond-view .MuiPaper-root.orkan-card .main-title {
  padding: 0 24px;
}

#choose-bond-view .MuiTable-root {
  margin: 0;
  //	border-bottom:1px solid rgba(0, 0, 0, 0.12);
}

#choose-bond-view .MuiTableCell-head {
  line-height: 24px;
  padding: 8px 24px;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 12px;
  //    color: var(--white-color);
}

#choose-bond-view .MuiTableRow-root {
  border-top: 1px solid var(--light-bg-color);
}

.dark #choose-bond-view .MuiTableRow-root {
  border-top: 1px solid var(--dark-grey-color);
}

#choose-bond-view .MuiTableBody-root .MuiTableCell-root {
  padding: 16px 24px;
  font-size: 14px;
}

.tablet #choose-bond-view {
  .claim-bond-card-buttons {
    button {
      width: 40%;
    }
  }
}

.mobile #choose-bond-view {
  .claim-bond-card-buttons {
    flex-direction: column;

    button {
      width: 100%;
    }
  }
}

#choose-bond-view .main-title {
  margin-bottom: 24px;
}

#choose-bond-view .bond-hero {
  width: 100%;
  width: calc(100% + 16px);
  margin: -8px;
  margin-bottom: 24px;
}

#choose-bond-view .bond-block {
  display: flex;
  align-items: flex-start;
  padding: 24px;
  background: var(--light-white-color);
  border-radius: 9px;
}

#choose-bond-view .bond-block .bond-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 48px;
}

#choose-bond-view .bond-block .bond-icon span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 9px;
  border: 1px solid var(--half-white-color);
  padding: 13px;
}

#choose-bond-view .bond-block .bond-icon span img {
  height: 24px;
  width: auto;
}

#choose-bond-view .bond-block .bond-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 24px;
  text-align: left;
  color: var(--white-color);
}

#choose-bond-view .bond-block .bond-content .MuiBox-root {
  color: var(--white-color);
}

#choose-bond-view .bond-block .bond-content .MuiBox-root svg {

  fill: var(--white-color);
}

#choose-bond-view .bond-block .bond-content .MuiTypography-h5 {
  font-weight: 300;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 16px;
  color: var(--white-color);
}