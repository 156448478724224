#hades {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  background-color: #55555555;

  .orkan-popover {
    border: 1px solid rgb(140 142 150 / 24%);
    max-width: 500px;
    display: flex;
    flex-direction: column;

    h3 {
      align-self: center;
      justify-self: center;
      width: 75%;
      text-align: center;
    }

    label {
      margin: 33px 0 10px;
    }

    .help-text {
      margin: 10px;
    }
  }

  .mobile .orkan-popover {
    max-width: 400px;
  }
}