* {
	outline: none;
	-ms-overflow-style: none !important;
	/* IE and Edge */
	scrollbar-width: none !important;

	/* Firefox */
	&::-webkit-scrollbar {
		display: none !important;
	}
}

:root {
	--main-color: #242836;
	--dark-main-color: #131B24;

	--sub-color: #E59310;
	--dark-sub-color: #DA5716;

	--grey-color: #8C8E96;
	--dark-grey-color: #353946;

	--light-bg-color: #F3F3F7;

	--white-color: #FFFFFF;
	--half-white-color: rgba(255, 255, 255, 0.48);
	--light-white-color: rgba(255, 255, 255, 0.16);
	--box-shadow: 0px 8px 16px rgba(26, 26, 26, 0.08);
}

html,
body,
#root {
	font-family: "Square";
	font-family: "azo-sans-web", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow: hidden;
}

body {
	margin: 0;
	box-sizing: border-box;
}

// body > iframe {
// 	display: none !important;
// }

.app {
	height: 100vh;
	width: 100vw;
	display: flex;
	flex-flow: column;
	flex-direction: column;
	z-index: 1;
	background-size: cover;
	font-family: "Square";
	font-family: "azo-sans-web", sans-serif;
	overflow: hidden;
	overflow-y: auto;
	transition: all ease 0.33ms;
}

.MuiDrawer-paperAnchorDockedLeft {
	border-right: 0 !important;
}

h1 {
	margin: 0;
	font-size: 28px;
	font-weight: 500;
}

.main-container {
	width: 97%;
	max-width: 833px;
}

.main-title h1 {
	color: var(--white-color);
}

.main-title h2 {
	margin: 0;
	font-size: 20px;
	font-weight: 700;
}

.dark .orkan-popover {
	background: var(--main-color);
}

.MuiButton-outlinedPrimary:hover {
	background-color: #E59310 !important;
	border: 1px solid #E59310 !important;
	color: #fff !important;
}

.orkan-card {
	max-width: 833px;

	.card-header {
		width: 100%;
		min-height: 33px;
		margin-bottom: 10px;

		h5 {
			font-weight: 500 !important;
		}
	}

	.help-text {
		margin: 10px 0px;
	}

	.data-row {
		display: flex;
		justify-content: space-between;
		flex-direction: row;
		margin: 12px 0px;
	}

	.data-row-centered {
		display: flex;
		justify-content: center;
		flex-flow: row wrap;
		margin: 12px 0px;

		.marginedBtn {
			margin: 0.3rem;
		}
	}
}

.orkan-pairs {
	display: flex;
	align-items: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none !important;
}

input[type="number"] {
	-moz-appearance: textfield !important;
}

a:hover svg {
	color: inherit;
}

.dark .MuiButton-containedPrimary {
	background: var(--sub-color);
}


/* HEADER STYLES *****************************/

.header-main {
	position: relative !important;
	display: flex;
	flex-direction: row !important;
	background: none !important;
	backdrop-filter: none !important;
	padding: 0 48px;
	z-index: 9 !important;
}

.header-main:after {
	content: '';
	display: block;
	position: absolute;
	width: 100%;
	height: 1px;
	background: var(--light-white-color);
	position: absolute;
	bottom: 0;
	left: 0;
}

.nav-main-wrapper {
	position: relative;
	display: flex;
	flex-direction: row;
}

.nav-logo {
	position: relative;
	display: block;
	margin: 20px 0px;
}

.nav-logo img {
	height: 40px;
	width: auto;
	vertical-align: top;
}

.nav-main {
	position: relative;
	display: flex;
	flex-direction: row;
	padding: 20px 24px;
}

.nav-main a {
	position: relative;
	display: block;
	margin: 0 4px;
	padding: 8px 12px;
	line-height: 24px;
	border-radius: 9px;
	font-size: 14px;
	font-weight: 700;
	background: none;
	color: var(--white-color);
}

.nav-main a.MuiLink-root.active {
	background: var(--light-white-color);
	color: var(--white-color);
	text-decoration: none !important;
}

.nav-main a:hover {
	background: var(--light-white-color);
	color: var(--white-color);
}

.nav-main a div {
	position: relative;
	display: flex;
	flex-direction: row;
	white-space: nowrap;
}

.header-main .dapp-topbar {
	margin-left: auto;
}

.nav-sidebar-logo {
	position: relative;
	margin-bottom: 16px;
}

.nav-sidebar-logo img {
	height: 40px;
	width: auto;
	vertical-align: top;
}


/* MAIN STYLES *****************************/

.page-wrapper {
	position: relative;
	margin: 0px;
	padding: 128px 0px 72px 0px;
	padding: 48px 0px 72px 0px;
}

.page-wrapper-bg {
	position: absolute;
	height: 384px;
	width: 100%;
	top: -80px;
	left: 0;
	background: transparent linear-gradient(101deg, var(--sub-color) 0%, var(--dark-sub-color) 100%) 0% 0% no-repeat padding-box;
	z-index: -1;
}

.sidebar {
	display: none;
}

.main-title {
	text-align: left;
}


/* RESPONSIVE STYLES *****************************/


@media (max-width: 767px) {

	.nav-main-wrapper {
		display: none;
	}

	.page-wrapper {
		padding: 24px 0px 0px 0px !important;
	}

	.dapp-topbar button#hamburger {
		margin-left: 0 !important;
	}

	#treasury-dashboard-view .metric,
	#stake-view .stake-block,
	#choose-bond-view .bond-block {
		flex-direction: row;
		align-items: center;
	}

	#treasury-dashboard-view .hero-metrics .orkan-card div.metric,
	#stake-view .stake-block,
	#choose-bond-view .bond-block {
		width: 100%;
		max-width: 100%;
		margin-left: 0;
		margin-right: 0;
		padding: 16px;
		margin-bottom: 12px;
	}

	#treasury-dashboard-view .metric .metric-icon,
	#stake-view .stake-block .stake-icon,
	#choose-bond-view .bond-block .bond-icon {
		padding-bottom: 0;
	}

	#stake-view .stake-block .stake-icon,
	#choose-bond-view .bond-block .bond-icon {
		margin-bottom: 0;
	}


	#treasury-dashboard-view .metric .metric-content,
	#stake-view .stake-block .stake-content {
		padding-left: 16px;
	}

	#stake-view .page-wrapper-bg {
		height: 554px;
	}

	#stake-view .MuiGrid-item:first-child {
		margin-top: 16px;
	}

	#stake-view .MuiGrid-item {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}

	#stake-view .rebase-timer {
		position: relative
	}

	#treasury-dashboard-view .page-wrapper-bg {
		height: 872px;
	}

	#treasury-dashboard-view .hero-metrics {
		margin-bottom: 24px;
	}

	#treasury-dashboard-view .MuiGrid-root {
		padding-left: 6px;
		padding-right: 6px;
	}

	#choose-bond-view .bond-hero {
		display: flex;
		flex-direction: column;
	}

	#choose-bond-view .MuiPaper-root.orkan-card {
		padding: 12px !important;
	}

	#choose-bond-view .bond-hero .MuiGrid-item:last-child {
		padding-left: 0;
		padding-top: 0;
	}

	#choose-bond-view .bond-hero {
		margin-bottom: 0;
	}

	#choose-bond-view .bond-hero .MuiGrid-item:first-child {
		padding-right: 0;
		padding-bottom: 0;
	}

	.dashboard-title h1 {
		color: #fff;
	}

	#choose-bond-view {
		padding-bottom: 48px !important;
	}

	.bond-data-card button h5 {
		font-size: 14px;
		font-weight: 500;
	}

	#choose-bond-view .bond-hero .MuiGrid-item {
		width: 100%;
		max-width: 100%;
	}

	.chart-card-header .card-title-text {
		font-size: 12px;
	}

	.dashboard-title {
		padding-left: 0;
	}

	.page-wrapper {
		padding: 48px 0px 48px 0px;
	}

	.header-main {
		padding: 0px 24px;
	}

	.MuiContainer-root {
		padding: 0 24px !important;
	}

	#stake-view,
	#choose-bond-view {
		padding: 0 24px;
	}

	#choose-bond-view .bond-hero {
		width: 100%;
		margin-left: 0;
		margin-right: 0;
	}

	#choose-bond-view .bond-hero .MuiGrid-item:first-child {
		padding-left: 0;
	}

	#choose-bond-view .bond-hero .MuiGrid-item:last-child {
		padding-right: 0;
	}

	#choose-bond-view .MuiPaper-root.orkan-card {
		padding: 16px 16px 16px 16px;
	}

	#stake-view.wrap-view .data-row .MuiTypography-root.MuiTypography-body1:last-child {
		text-align: right;
	}
}