.wrap-view .main-title {
    margin-bottom: 24px;
}


.wrap-view .bond-block {
    display: flex;
    align-items: flex-start;
    padding: 24px;
    background: var(--light-white-color);
    border-radius: 9px;
}

.wrap-view .bond-block .bond-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 48px;
}

.wrap-view .bond-block .bond-icon span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 9px;
    border: 1px solid var(--half-white-color);
    padding: 13px;
}

.wrap-view .bond-block .bond-icon span img {
    height: 24px;
    width: auto;
}

.wrap-view .bond-block .bond-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 24px;
    text-align: left;
    color: var(--white-color);
}

.wrap-view .bond-block .bond-content .MuiBox-root {
    color: var(--white-color);
}

.wrap-view .bond-block .bond-content .MuiBox-root svg {
    fill: var(--white-color);
}

.wrap-view .bond-block .bond-content .MuiTypography-h5 {
    font-weight: 300;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 16px;
    color: var(--white-color);
}

.orkan-card {
    margin-right: 0;
}

#stake-view.wrap-view .stake-top-metrics {
    margin-bottom: 40px;
}

#stake-view.wrap-view .MuiPaper-root.orkan-card {
    width: 100%;
}