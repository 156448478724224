#orkan-menu-button {
  padding: 12px;
  padding-left:8px;
  padding-right:8px;
  svg {
    margin-right: 3px;
    color: inherit !important;
  }
}
.orkan-menu-button-hover {
  z-index: 9;
}
#orkan-popper,
#orkan-popper-pending {
  z-index: 5;
  background-color: #00000003;
  backdrop-filter: blur(33px);
  -webkit-backdrop-filter: blur(33px);
  margin-top: 16px;
  border-radius: 10px;
  .MuiPaper-root.orkan-menu {
    padding: 16px 6px;
    display: flex;
    flex-direction: column;
    button {
      display: block;
      width: 100%;
      text-align: left;
      margin-bottom: 4px;
      padding-left: 16px;
      &:not(:hover) {
        background-color: #00000003 !important;
      }
    }
    a {
      svg {
        vertical-align: middle;
        font-size: 20px;
        margin-left: 3px;
      }
    }
    hr {
      &.less-margin {
        margin-bottom: 5px;
      }
      margin-bottom: 18px;
    }
  }

  .add-tokens {
    margin-bottom: 15px;
    > p {
      margin: 15px 0px 5px 0px;
      text-align: left;
      padding-left: 16px;
    }
    height: max-content !important;
    width: 100%;
    button {
      min-height: 56px !important;
      text-align: center !important;
      padding-bottom: 60px;
      padding-top: 12px;
    }
  }

  .buy-tokens {
    padding-bottom: 8px;
  }
}
