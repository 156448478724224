#treasury-dashboard-view {
  overflow-y: hidden;
  overflow-x: hidden;
  height: 100%;
  // width: 100%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
//  overflow-y: auto;
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
  &::-webkit-scrollbar {
    display: none !important;
  }

	.orkan-card {
		display: flex;
		max-width: unset !important;
		width: 100%;
		margin: auto;
		padding: 0;
		background: var(--white-color);
		box-shadow: var(--box-shadow);
		border-radius: 9px;
//		backdrop-filter: none;
	  
	  
//    max-height: 355px;
	  
    .card-header {
      margin-bottom: 1rem;
    }
    &.orkan-chart-card {
      .chart-card-header {
        padding: 0px 0px !important;
        min-width: 300px;
      }
//      height: 355px;
    }
  }
  .hero-metrics {
    width: 100%;
    margin-bottom: 48px;
    text-align: center;
    .orkan-card {
		background: transparent; 
		box-shadow: none;
		width: 100%;
		max-width: unset !important;
		width: initial !important;
		min-height: initial;
		flex-direction: row;
		flex-wrap: wrap;
      h5 {
        font-weight: bold;
      }
      div.metric {
		width: 30%;
		width: calc(33.3333% - 24px);
		padding: 24px;
		margin: 0 12px 24px 12px;
		background: var(--light-white-color);
		border-radius: 9px;
		  
		  
      }
    }
  }
  .data-grid {
    overflow: hidden;
  }
}


#treasury-dashboard-view .hero-metrics .orkan-card > div{
	width: 100%;
}
#treasury-dashboard-view .metric{
	display: flex;
}
#treasury-dashboard-view .metric .metric-icon{
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 48px;
}
#treasury-dashboard-view .metric .metric-icon span{
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 48px;	
	height: 48px;
	border-radius: 9px;
	border: 1px solid var(--half-white-color);
	padding: 13px;
}
#treasury-dashboard-view .metric .metric-icon span img{
	height: 24px;
	width: auto;
}
#treasury-dashboard-view .metric .metric-content{
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-left: 24px;
	text-align: left;
	color: var(--white-color);
}
#treasury-dashboard-view .metric .metric-content .MuiBox-root{
	color: var(--white-color);
}
#treasury-dashboard-view .metric .metric-content .MuiBox-root svg{

	fill:var(--white-color);
//	height: 16px;
//	width: 16px;
//	font-size: 16px;
}
#treasury-dashboard-view .hero-metrics .orkan-card .MuiTypography-h6{
	font-weight: 300;
	text-transform: uppercase;
	font-size: 12px;
	line-height: 16px;
}

.smaller {
  .orkan-card {
    width: 97%;
  }
  .hero-metrics .orkan-card {
    > .MuiBox-root {
      flex-direction: column;
      align-items: flex-start;
      flex-wrap: wrap;
    }
    div.metric {
      text-align: left !important;
    }
  }
}

.mobile {
  div.metric {
    white-space: nowrap;
    min-width: 30% !important;
    max-width: 60%;
    text-align: left !important;
    &.market,
    &.price,
    &.wsoprice,
    &.circ,
    &.bpo,
    &.index {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}


.very-small {
  .hero-metrics .orkan-card {
    > .MuiBox-root {
      flex-direction: column;
      justify-content: center;
    }
  }
}

#treasury-dashboard-view .recharts-tooltip-wrapper .orkan-card{
	background: var(--white-color);
	box-shadow: var(--box-shadow);
	display: flex;
	flex-direction: column;
	padding: 16px 24px;
}


.dashboard-wrapper{
	padding: 24px 0px;
}
.dashboard-title{
	padding: 0px 12px;
}

.data-grid{
	padding: 24px 12px;
}

.chart-card-box{
	position: relative;
	width: 100%;
	padding: 24px;
	min-height: 240px;
}
.chart-card-header svg{
	fill: var(--grey-color);
}



.dark #treasury-dashboard-view .dashboard-wrapper .orkan-card{
	background: var(--main-color);
}
.dark .chart-card-header svg{
	fill: var(--grey-color);
}