.dark .dapp-topbar button {
  background-color: rgba(255, 255, 255, 0.15) !important;
}

.dark .dapp-topbar button:hover {
  background-color: rgba(255, 255, 255, 0.08) !important;
}

.dark .dapp-topbar button:hover .MuiButton-label {
  color: rgba(255, 255, 255, 0.64) !important;
}

.dark .dapp-topbar button:hover .MuiToggleButton-label {
  opacity: 0.64 !important;
}

.light .dapp-topbar button {
  background-color: rgba(255, 255, 255, 0.15) !important;
  color: #fff !important;
}

.light .dapp-topbar button:hover {
  background-color: rgba(255, 255, 255, 0.08) !important;
}

.light .dapp-topbar button:hover .MuiButton-label {
  color: rgba(255, 255, 255, 0.64) !important;
}

.light .dapp-topbar button:hover .MuiToggleButton-label {
  opacity: 0.64 !important;
}

.light .dapp-topbar button .MuiToggleButton-label svg {
  fill: #fff !important;
}

// .light .dapp-topbar button {
//   background-color: rgba(255, 255, 255, 0.15) !important;
// }

.dapp-topbar {
  width: 100%;
  align-items: center;
  button {

    white-space: nowrap !important;
    overflow: hidden !important;
    margin: 8px !important;
    min-width: fit-content !important;
    &#hamburger {
      padding: 11px !important;
    }
    &.toggle-button {
      padding: 9.5px !important;
    }
  }
}
.tablet .dapp-topbar {
  justify-content: space-between;
}
.pending-txn-container {
  display: flex;
  align-items: center;
  .MuiButton-label {
    margin-right: 0;
    transition: all 0.33s ease-out;
  }
}
#orkan-popper-pending {
  .MuiPaper-root.orkan-menu {
    a {
      padding-right: 20px !important;
    }
  }
}
.caret-down {
  position: absolute;
  right: 0.4rem;
}
.hovered-button {
  .MuiButton-label {
    margin-right: 0.5rem;
    transition: all 0.33s ease-out;
  }
}
