#dashboard-view {
  overflow-y: hidden;
  overflow-x: hidden;
  height: 100%;
  .orkan-card {
    white-space: nowrap;
    width: auto;
    margin: auto;
  }
}

.top-row-data {
  position: sticky;
  overflow: hidden;
  flex-wrap: wrap;
  z-index: 5;
  h5 {
    font-weight: 600 !important;
  }
}

.main-data-area {
  overflow-x: hidden;
  flex-flow: wrap;
  display: flex !important;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
  height: 73vh;
  z-index: 2;
  .MuiGrid-root.MuiGrid-item {
    display: flex;
    justify-content: center !important;
    justify-self: center !important;
  }
  .data-grid {
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .dune-card {
    height: auto;
    min-width: 333px;
    max-width: 700px;
    width: auto;
    flex: auto;
    box-shadow: 0px 2px 28px rgba(0, 0, 0, 0.03);
    background-color: #ffffff0e;
    backdrop-filter: blur(60px);
    -webkit-backdrop-filter: blur(60px);
    border-radius: 5px !important;
    iframe {
      width: 100%;
      height: 100%;
      min-height: 300px;
    }
  }
}
