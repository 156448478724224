#stake-view .page-wrapper-bg{
	height: 424px;
}

#stake-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .rebase-timer {
	  position: absolute;
	  right: 0;
	  top: 0;
	  height: 24px;
	  padding: 4px 12px;
	  line-height: 16px;
	  font-size: 11px;
	  border-radius: 9px;
	  background: var(--light-bg-color);
		margin: 0 !important;
	  z-index: 2;
  }
	.rebase-timer .MuiTypography-body2{
		font-size: 11px;
		text-transform: uppercase;
		line-height: 16px;
	}

  .stake-tab-buttons {
    margin-bottom: 0.25rem;
  }

  .stake-table-panel,
  .stake-tab-panel > div.MuiBox-root {
    width: 100%;
    padding: 0;
  }
  .mobile .stake-tab-panel,
  .mobile .stake-tab-panel > div.MuiBox-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
  }

	.orkan-card {
		box-shadow: var(--box-shadow);
	}
  .orkan-card .card-header {
    position: relative;
  }
  .orkan-card .header {
    min-height: 3em;
  }
  .orkan-card.secondary .card-header {
    min-height: 33px !important;
    height: 20px;
  }
  .stake-note {
//    text-align: center;
    font-style: italic;
  }
}

.dark #stake-view .rebase-timer {
  	background: var(--dark-main-color);
}

#stake-view .stake-top-metrics{
	width: 100%;
	margin-bottom: 24px;
}
#stake-view .stake-block{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 24px;
    background: var(--light-white-color);
    border-radius: 9px;
}
#stake-view .stake-block .stake-icon{
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 48px;
	margin-bottom: 24px;
}
#stake-view .stake-block .stake-icon span{
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 48px;	
	height: 48px;
	border-radius: 9px;
	border: 1px solid var(--half-white-color);
	padding: 13px;
}
#stake-view .stake-block .stake-icon span img{
	height: 24px;
	width: auto;
}
#stake-view .stake-block .stake-content{
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: left;
	color: var(--white-color);
}
#stake-view .stake-block .stake-content .MuiBox-root{
	color: var(--white-color);
}
#stake-view .stake-block .stake-content .MuiBox-root svg{

	fill:var(--white-color);
}
#stake-view .stake-block .stake-content .MuiTypography-h5{
	font-weight: 300;
	text-transform: uppercase;
	font-size: 12px;
	line-height: 16px;
	color: var(--white-color);
}

.migrate-sorkan-button {
  position: absolute;
  right: 0px;
  top: 0px;
  font-size: 1em;
  display: flex;
  align-items: center;
  color: #f08080;
  line-height: 30px;
  svg {
    margin-right: 8px;
    color: inherit;
  }
}

.migrate-sorkan-button.complete {
  color: #35ee66 !important;
}

.stake-top-metrics {
  display: flex;
  width: 100%;
  padding: 0;
  text-align: center;
  margin-top: 10px;
  h5 {
    font-weight: 400;
  }
  h4 {
    font-weight: 500;
  }
  .stake-apy,
  .stake-tvl,
  .stake-index {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-overflow: ellipsis;
    // white-space: nowrap;
    overflow: hidden;
  }
}

.mobile .stake-top-metrics {
  .stake-apy,
  .stake-tvl,
  .stake-index {
    align-items: flex-start;
  }
}

.orkan-logo-tiny {
  height: 14px;
  width: 14px;
  vertical-align: middle;
  background-color: transparent;
  align-self: center;
  justify-self: center;
}

.logo-holder {
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  background: none;
  border: 0 !important;
  border-radius: inherit;
  color: #555;
}

.stake-action-area {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
//  justify-content: space-around;
//  margin-top: -30px;
}

.mobile .stake-action-area {
  margin-top: 20px;
}

.stake-action-row {
//  justify-content: space-around;
  align-items: center;
  height: auto;
  padding-bottom: 1rem;
}

.mobile .stake-action-row {
  justify-content: center;
}

.stake-action-row [role="tabpanel"] {
  width: 99%;
  min-width: 162px;
  max-width: 220px;
  margin: 5px;
  margin-left: auto;
}

.mobile .stake-action-row {
  flex-direction: column;
  [role="tabpanel"] {
    max-width: 100%;
    width: 100% !important;
  }
}

.orkan-input {
  max-width: 542px;
  width: 100%;
  margin: 5px !important;
}

.stake-button {
  align-self: center;
  width: 100%;
  min-width: 163px !important;
  max-width: 542px !important;
  height: 43px !important;
}

.mobile .stake-button {
  width: 100% !important;
  max-width: 542px;
  align-self: center;
  justify-self: center;
}

.staking-area {
	position: relative;
//  margin: 13px 10px 13px 10px;
	margin: 0;
}

.stake-lp-button {
  text-align: center !important;
  display: flex !important;
  justify-content: center !important;
  svg {
    margin-left: 4px;
    vertical-align: middle;
    font-size: 1.1em;
  }
  &:hover {
    svg {
      color: inherit;
    }
  }
}

.stake-user-data {
  justify-content: center;
  margin: auto;
  padding: 0 5px;
}

.stake-user-data .data-row-sm{
	
}

.tablet .stake-pool,
.mobile .stake-pool {
  .pool-card-top-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .orkan-pairs p {
      margin-bottom: 0 !important;
    }
  }

  .pool-data {
    margin-top: 15px;
    width: 100%;
    .pool-data-row {
      display: flex;
      justify-content: space-between;
      line-height: 225%;
    }
  }
  .stake-lp-button {
    margin-top: 10px;
    svg {
      margin-left: 10px !important;
    }
  }
}

.stake-wallet-notification {
  text-align: center;
  .wallet-menu {
    text-align: center;
    .connect-button {
      max-width: 300px !important;
      min-width: 240px !important;
      margin-bottom: 15px;
    }
  }
}
